<template>
  <fieldset class="mt-3">
    <div class="row mt-3">
      <div class="col-12">
        <div class="form-check form-switch">
          <input
            id="faqs-toggle"
            v-model="faqs.status"
            class="form-check-input"
            type="checkbox"
            @change="$emit('toggleFaqs')"
          />
          <label class="form-check-label" for="faqs-toggle">FAQS Section</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <span class="d-block">Content</span>
        <button
          v-show="showHideAddMoreFaqs"
          class="btn btn-outline-dark mt-2"
          @click="addMoreFaqs"
        >
          Add More
        </button>
      </div>
      <div class="row">
        <div class="col-6">
          <fieldset>
            <div
              v-for="(content, index) in faqs.content"
              :key="index"
              class="card mt-3"
            >
              <div class="card-body">
                <img
                  alt="Close"
                  class="cursor-pointer mb-2 float-right"
                  src="@/assets/icons/icon-close.svg"
                  @click="removeFaqs(index)"
                />
                <div class="row">
                  <div class="col">
                    <fieldset>
                      <span>Title</span>
                      <div class="card mt-3">
                        <div class="card-body">
                          <div class="form-group full-width">
                            <label>Text</label>
                            <textarea
                              v-model="content.title"
                              class="form-control mt-2"
                              required
                              rows="6"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="col">
                    <fieldset>
                      <span>Description</span>
                      <div class="card mt-3">
                        <div class="card-body">
                          <div class="form-group full-width">
                            <label>Text</label>
                            <textarea
                              v-model="content.description"
                              class="form-control mt-2"
                              required
                              rows="6"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-6">
          <fieldset>
            <div class="card mt-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <fieldset>
                      <span>Title</span>
                      <div class="card mt-3">
                        <div class="card-body">
                          <div class="form-group full-width">
                            <label>Text</label>
                            <textarea
                              v-model="faqs.title"
                              class="form-control mt-2"
                              required
                              rows="6"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="col-6">
                    <fieldset>
                      <span>Other</span>
                      <div class="card mt-3">
                        <div class="card-body">
                          <div class="form-group full-width">
                            <label>Text</label>
                            <div class="mt-2">
                              <ckeditor
                                :editor="editor"
                                v-model="faqs.other"
                                :config="editorConfig"
                              >
                              </ckeditor>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "FaqsSection",
  props: {
    fAQSData: { type: Object }
  },
  data() {
    return {
      faqs: this.fAQSData,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ["bold", "|", "italic", "|", "link"]
      }
    };
  },
  methods: {
    addMoreFaqs() {
      this.faqs.content.push({
        title: "",
        description: ""
      });
    },
    removeFaqs(index) {
      this.$delete(this.faqs.content, index);
    }
  },
  watch: {
    faqs: {
      handler(val) {
        this.$emit("update-faqs", val);
      },
      deep: true
    }
  },
  computed: {
    /** Show Hide Add More Button Section */
    showHideAddMoreFaqs() {
      let target = this.faqs.content;
      let len = target.length;
      if (len > 0) {
        let content = target[len - 1];
        if (content.title.trim() === "") {
          return false;
        }
        if (content.description.trim() === "") {
          return false;
        }
        return true;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-footer-title {
  min-height: 285px;
}
.card-fixed {
  height: auto;
  max-height: 650px;
  overflow-y: auto;
}
.card-body {
  min-height: 250px;
}
.card-body-title {
  color: #6c757d;
  display: inline-block;
  margin-bottom: 10px;
}
.full-width {
  max-width: 100%;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
}
.form-group {
  textarea {
    line-height: 20px;
  }
}
.form-switch {
  padding-left: 0;
  .form-check-input {
    margin: 0;
  }
  .form-check-label {
    cursor: pointer;
  }
}
</style>

<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title text-uppercase">Configuration</h3>
      </div>
      <div class="page-header-buttons d-flex">
        <base-button title="Reset to default" action="secondary" :disabled="formResetloading || formloading"
                     @click-btn="resetToDefault" :loading="formResetloading" />
        <router-link :to="{ name: 'mlo_landing' }" v-if="activeTab === 'landing'" class="ms-2 text-decoration-none">
         <base-button title="Preview" action="secondary" />
        </router-link>
      </div>
    </div>
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li
          :class="{ active: activeTab === 'landing' }"
          @click="setActiveTab('landing')"
        >
          Landing
        </li>
        <li
          :class="{ active: activeTab === 'core' }"
          @click="setActiveTab('core')"
        >
          Core Values
        </li>
        <li
          :class="{ active: activeTab === 'rating' }"
          @click="setActiveTab('rating')"
        >
          Rating
        </li>
        <li
          :class="{ active: activeTab === 'welcome_funnel' }"
          @click="setActiveTab('welcome_funnel')"
        >
          Welcome Funnel
        </li>
      </ul>
    </div>
    <div v-if="loading" class="text-center mt-5">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else class="page-content p-normal">
      <transition name="component-fade" mode="out-in">
        <div v-if="activeTab === 'landing'">
          <!-- Header Section -->
          <HeroSection
            :headerData="fields.header"
            @toggleHeader="toggleHeader"
            @update-header="updateHeader"
          />

          <!-- Why Choose Us Section -->
          <WhyChooseUs
            :whyChooseData="fields.why_choose"
            @toggleWhyChoose="toggleWhyChoose"
            @update-whychoose="updateWhyChoose"
          />

          <!-- Current Rate Section -->
          <CurrentRate
            :currentRateData="fields.current_rate"
            @toggleCurrentRate="toggleCurrentRate"
            @update-currentrate="updateCurrentRate"
          />

          <!-- Loan Options Section -->
          <LoanOptions
            :loanOptionsData="fields.loan_option"
            @toggleLoanOption="toggleLoanOption"
            @update-loanoptions="updateLoanOptions"
          />

          <!-- How It Works Section -->
          <HowItWorks
            :howItWorksData="fields.how_it_work"
            @toggleHowItWork="toggleHowItWork"
            @update-howitworks="updateHowItWorks"
          />

          <div class="row">
            <div class="col-6">
              <!-- Reviews Section -->
              <Reviews
                :reviewsData="fields.review"
                @toggleReview="toggleReview"
                @update-reviews="updateReviews"
              />
            </div>
            <div class="col-6">
              <!-- Ratings Section -->
              <Ratings
                :ratingsData="fields.rating"
                @toggleRating="toggleRating"
                @update-ratings="updateRatings"
              />
            </div>
          </div>

          <!-- Our Lenders Section -->
          <Lenders
            :lendersData="fields.lender"
            @toggleLender="toggleLender"
            @update-lenders="updateLenders"
          />

          <!-- Team of Experts Section -->
          <Team
            :teamData="fields.team"
            @toggleTeam="toggleTeam"
            @update-team="updateTeam"
          />

          <!-- Counter Section -->
          <Counter
            :counterData="fields.counter"
            @toggleCounter="toggleCounter"
            @update-counter="updateCounter"
          />

          <!-- Get In Touch Section -->
          <GetInTouch
            :getInTouchData="fields.get_in_touch"
            @toggleGetInTouch="toggleGetInTouch"
            @update-getintouch="updateGetinTouch"
          />

          <!-- FAQS Section -->
          <FaqsSection
            :fAQSData="fields.faqs"
            @toggleFaqs="toggleFaqs"
            @update-faqs="updateFAQS"
          />

          <!-- Widget Section -->
          <Widget
            :widgetData="fields.widget"
            @toggleWidget="toggleWidget"
            @update-widget="updateWidget"
          />

          <!-- Footer Section -->
          <Footer
            :footerData="fields.footer"
            @toggleFooter="toggleFooter"
            @update-footer="updateFooter"
          />
        </div>
        <div v-else-if="activeTab === 'core'">
          <div class="row">
            <div class="col-6">
              <!-- Block Title Section -->
              <BlockTitle
                :blockData="coreFields.blockTitle"
                @update-blocktitle="updateBlockTitle"
              />
            </div>
            <div class="col-6">
              <!-- Header Section -->
              <Header
                :headerData="coreFields.header"
                @toggleHeader="toggleCoreHeader"
                @update-header="updateCoreHeader"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6 mt-3">
              <!-- Block One Section -->
              <BlockOne
                :blockOneData="coreFields.blockOne"
                @toggleBlockOne="toggleBlockOne"
                @update-blockone="updateBlockOne"
              />
            </div>
            <div class="col-6 mt-3">
              <!-- Block Two Section -->
              <BlockTwo
                :blockTwoData="coreFields.blockTwo"
                @toggleBlockTwo="toggleBlockTwo"
                @update-blocktwo="updateBlockTwo"
              />
            </div>
            <div class="col-6 mt-3">
              <!-- Block Three Section -->
              <BlockThree
                :blockThreeData="coreFields.blockThree"
                @toggleBlockThree="toggleBlockThree"
                @update-blockthree="updateBlockThree"
              />
            </div>
            <div class="col-6 mt-3">
              <!-- Block Four Section -->
              <BlockFour
                :blockFourData="coreFields.blockFour"
                @toggleBlockFour="toggleBlockFour"
                @update-blockfour="updateBlockFour"
              />
            </div>
            <div class="col-6 mt-3">
              <!-- Block Five Section -->
              <BlockFive
                :blockFiveData="coreFields.blockFive"
                @toggleBlockFive="toggleBlockFive"
                @update-blockfive="updateBlockFive"
              />
            </div>
          </div>
        </div>
        <div v-else-if="activeTab === 'rating'">
          <div class="row">
            <div class="col-4">
              <!-- Zillow Section -->
              <Zillow
                :zillowData="ratingFields.zillow"
                @toggleZillow="toggleZillow"
                @update-zillow="updateZillow"
              />
            </div>
            <div class="col-4">
              <!-- Google Section -->
              <Google
                :googleData="ratingFields.google"
                @toggleGoogle="toggleGoogle"
                @update-google="updateGoogle"
              />
            </div>
            <div class="col-4">
              <!-- Yelp Section -->
              <Yelp
                :yelpData="ratingFields.yelp"
                @toggleYelp="toggleYelp"
                @update-yelp="updateYelp"
              />
            </div>
          </div>
        </div>

        <div v-else>
          <div class="row mt-2">
            <div class="col-3">
              <div class="card">
                <div class="card-body">
                  <div class="form-group full-width">
                    <label class="pb-2">Title</label>
                    <textarea
                      class="form-control"
                      rows="6"
                      required
                      v-model="welcomeFunnel.title"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-9">
              <div class="card">
                <div class="card-body">
                  <label class="pb-2">Description</label>
                  <ckeditor
                    :editor="editor"
                    v-model="welcomeFunnel.description"
                    :config="editorConfig">
                  </ckeditor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <base-button title="Save" action="primary" :disabled="validForm || formloading" @click-btn="save"
                   :loading="formloading" class="btn-save" />
    </div>
  </div>
</template>

<script>
import HeroSection from "./includes/landing/HeroSection";
import WhyChooseUs from "./includes/landing/WhyChooseUs";
import CurrentRate from "./includes/landing/CurrentRate";
import LoanOptions from "./includes/landing/LoanOptions";
import HowItWorks from "./includes/landing/HowItWorks";
import Reviews from "./includes/landing/Reviews";
import Ratings from "./includes/landing/Ratings";
import Lenders from "./includes/landing/Lenders";
import Team from "./includes/landing/Team";
import Counter from "./includes/landing/Counter";
import GetInTouch from "./includes/landing/GetInTouch";
import FaqsSection from "./includes/landing/FaqsSection";
import Widget from "./includes/landing/Widget";
import Footer from "./includes/landing/Footer";

import Header from "./includes/core/Header";
import BlockTitle from "./includes/core/BlockTitle";
import BlockOne from "./includes/core/BlockOne";
import BlockTwo from "./includes/core/BlockTwo";
import BlockThree from "./includes/core/BlockThree";
import BlockFour from "./includes/core/BlockFour";
import BlockFive from "./includes/core/BlockFive";

import Zillow from "./includes/rating/Zillow";
import Google from "./includes/rating/Google";
import Yelp from "./includes/rating/Yelp";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BaseButton from '../../components/BaseButton.vue';

export default {
  name: "ConfigMLOLanding",
  components: {
    HeroSection,
    WhyChooseUs,
    CurrentRate,
    LoanOptions,
    HowItWorks,
    Reviews,
    Ratings,
    Lenders,
    Team,
    Counter,
    GetInTouch,
    FaqsSection,
    Widget,
    Footer,
    // Core Block
    Header,
    BlockTitle,
    BlockOne,
    BlockTwo,
    BlockThree,
    BlockFour,
    BlockFive,
    // rating Block
    Zillow,
    Google,
    Yelp,
    BaseButton
  },
  data() {
    return {
      activeTab: "landing",
      loading: true,
      formloading: false,
      formResetloading: false,
      id: 1,
      toggleEnMessage: "Are you sure you want to enable this section?",
      toggleDisMessage: "Are you sure you want to disable this section?",
      fields: {
        header: Object,
        why_choose: Object,
        current_rate: Object,
        loan_option: Object,
        how_it_work: Object,
        review: Object,
        rating: Object,
        lender: Object,
        team: Object,
        counter: Object,
        get_in_touch: Object,
        faqs: Object,
        widget: Object,
        footer: Object,
      },
      coreFields: {
        blockTitle: String,
        header: Object,
        blockOne: Object,
        blockTwo: Object,
        blockThree: Object,
        blockFour: Object,
        blockFive: Object,
      },
      ratingFields: {
        zillow: Object,
        google: Object,
        yelp: Object
      },
      welcomeFunnel: Object,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
              'heading',
              '|',
              'bold',
              'italic',
              'link',
              'bulletedList',
              'numberedList',
              'undo',
              'redo'
          ]
        }
      }
    };
  },
  methods: {
    setActiveTab(tabName) {
      this.activeTab = tabName;
      this.loadForm();
    },
    loadForm() {
      this.loading = true;
      if (this.activeTab === "landing") {
        this.getLandingData();
      } else if (this.activeTab === "core") {
        this.getCoreValueData();
      } else if (this.activeTab === "rating") {
        this.getRattingData();
      } else {
        this.getWelcomeData();
      }
    },
    getLandingData() {
      this.$http
        .get(`/api/v1/mlo/landing/config/${this.id}`)
        .then((res) => {
          this.fields = res.data;
        })
        .catch((err) => {
          this.$store.dispatch(
            "OPEN_STATUS_NOTIFICATION",
            (this.statusNotification = {
              msg: err.response.statusText,
              status: "error",
              delay: 2000,
            })
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCoreValueData() {
      this.$http
        .get(`/api/v1/mlo/core/config/${this.id}`)
        .then((res) => {
          this.coreFields = res.data;
        })
        .catch((err) => {
          this.$store.dispatch(
            "OPEN_STATUS_NOTIFICATION",
            (this.statusNotification = {
              msg: err.response.statusText,
              status: "error",
              delay: 2000,
            })
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getRattingData() {
      this.$http
        .get(`/api/v1/mlo/rating/config/${this.id}`)
        .then((res) => {
          this.ratingFields = res.data;
        })
        .catch((err) => {
          this.$store.dispatch(
            "OPEN_STATUS_NOTIFICATION",
            (this.statusNotification = {
              msg: err.response.statusText,
              status: "error",
              delay: 2000,
            })
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getWelcomeData() {
      this.$http
        .get(`/api/v1/mlo/welcome/config/${this.id}`)
        .then((res) => {
          this.welcomeFunnel = res.data;
        })
        .catch((err) => {
          this.$store.dispatch(
            "OPEN_STATUS_NOTIFICATION",
            (this.statusNotification = {
              msg: err.response.statusText,
              status: "error",
              delay: 2000,
            })
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      if (this.validForm) {
        this.$store.dispatch(
          "OPEN_STATUS_NOTIFICATION",
          (this.statusNotification = {
            msg: "Validation error. Please check your data!",
            status: "error",
            delay: 2000,
          })
        );
        return;
      }
      this.formloading = true;
      let url = `/api/v1/mlo/landing/config/${this.id}/update`,
        formData = this.fields;
      if (this.activeTab === "core") {
        url = `/api/v1/mlo/core/config/${this.id}/update`;
        formData = this.coreFields;
      } else if (this.activeTab === "rating") {
        url = `/api/v1/mlo/rating/config/${this.id}/update`;
        formData = this.ratingFields;
      } else if (this.activeTab === "welcome_funnel") {
        url = `/api/v1/mlo/welcome/config/${this.id}/update`;
        formData = this.welcomeFunnel;
      }
      this.$http
        .post(url, formData)
        .then(() => {
          this.loadForm();
          this.$store.dispatch(
            "OPEN_STATUS_NOTIFICATION",
            (this.statusNotification = {
              msg: "All Details has been successfully saved!",
              status: "success",
              delay: 2000,
            })
          );
        })
        .catch((err) => {
          this.$store.dispatch(
            "OPEN_STATUS_NOTIFICATION",
            (this.statusNotification = {
              msg: err.response.statusText,
              status: "error",
              delay: 2000,
            })
          );
        })
        .finally(() => {
          this.formloading = false;
        });
    },
    resetToDefault() {
      if (
        confirm(
          "Are you sure, you want to reset the Home Page content to default? This action can't be undo..."
        )
      ) {
        this.formResetloading = true;
        let url = `/api/v1/mlo/landing/config/${this.id}/reset`;
        if (this.activeTab === "core") {
          url = `/api/v1/mlo/core/config/${this.id}/reset`;
        } else if (this.activeTab === "rating") {
          url = `/api/v1/mlo/rating/config/${this.id}/reset`;
        } else if (this.activeTab === "welcome_funnel") {
          url = `/api/v1/mlo/welcome/config/${this.id}/reset`;
        }
        this.$http
          .get(url)
          .then(() => {
            this.loadForm();
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: "Content reset to default successfully!",
                status: "success",
                delay: 2000,
              })
            );
          })
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.formResetloading = false;
          });
      }
    },
    // Rating Section
    toggleZillow() {
      let value = this.ratingFields.zillow.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.ratingFields.zillow.status = !this.ratingFields.zillow.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/rating/config/${this.id}/toggle/zillow`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.ratingFields.zillow.status = !this.ratingFields.zillow.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/rating/config/${this.id}/toggle/zillow`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleGoogle() {
      let value = this.ratingFields.zillow.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.ratingFields.google.status = !this.ratingFields.google.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/rating/config/${this.id}/toggle/google`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.ratingFields.google.status = !this.ratingFields.google.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/rating/config/${this.id}/toggle/google`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleYelp() {
      let value = this.ratingFields.yelp.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.ratingFields.yelp.status = !this.ratingFields.yelp.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/rating/config/${this.id}/toggle/yelp`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.ratingFields.yelp.status = !this.ratingFields.yelp.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/rating/config/${this.id}/toggle/yelp`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    // Core Values Section
    toggleCoreHeader() {
      let value = this.coreFields.header.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.coreFields.header.status = !this.coreFields.header.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/header`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.coreFields.header.status = !this.coreFields.header.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/header`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleBlockOne() {
      let value = this.coreFields.blockOne.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.coreFields.blockOne.status = !this.coreFields.blockOne.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/block-one`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.coreFields.blockOne.status = !this.coreFields.blockOne.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/block-one`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleBlockTwo() {
      let value = this.coreFields.blockTwo.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.coreFields.blockTwo.status = !this.coreFields.blockTwo.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/block-two`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.coreFields.blockTwo.status = !this.coreFields.blockTwo.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/block-two`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleBlockThree() {
      let value = this.coreFields.blockThree.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.coreFields.blockThree.status =
              !this.coreFields.blockThree.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/block-three`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.coreFields.blockThree.status =
              !this.coreFields.blockThree.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/block-three`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleBlockFour() {
      let value = this.coreFields.blockFour.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.coreFields.blockFour.status =
              !this.coreFields.blockFour.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/block-four`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.coreFields.blockFour.status =
              !this.coreFields.blockFour.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/block-four`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleBlockFive() {
      let value = this.coreFields.blockFive.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.coreFields.blockFive.status =
              !this.coreFields.blockFive.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/block-five`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.coreFields.blockFive.status =
              !this.coreFields.blockFive.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/core/config/${this.id}/toggle/block-five`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    // Landing Section
    toggleHeader() {
      let value = this.fields.header.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.header.status = !this.fields.header.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/hero`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.header.status = !this.fields.header.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/hero`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleWhyChoose() {
      let value = this.fields.why_choose.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.why_choose.status = !this.fields.why_choose.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/whychoose`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.why_choose.status = !this.fields.why_choose.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/whychoose`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleCurrentRate() {
      let value = this.fields.current_rate.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.current_rate.status = !this.fields.current_rate.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/currentrate`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.current_rate.status = !this.fields.current_rate.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/currentrate`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleLoanOption() {
      let value = this.fields.loan_option.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.loan_option.status = !this.fields.loan_option.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/loanoptions`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.loan_option.status = !this.fields.loan_option.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/loanoptions`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleHowItWork() {
      let value = this.fields.how_it_work.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.how_it_work.status = !this.fields.how_it_work.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/howitwork`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.how_it_work.status = !this.fields.how_it_work.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/howitwork`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleReview() {
      let value = this.fields.review.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.review.status = !this.fields.review.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/raview`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.review.status = !this.fields.review.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/review`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleRating() {
      let value = this.fields.rating.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.rating.status = !this.fields.rating.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/rating`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.rating.status = !this.fields.rating.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/rating`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleLender() {
      let value = this.fields.lender.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.lender.status = !this.fields.lender.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/lender`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.lender.status = !this.fields.lender.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/lender`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleTeam() {
      let value = this.fields.team.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.team.status = !this.fields.team.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/team`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.team.status = !this.fields.team.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/team`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleCounter() {
      let value = this.fields.counter.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.counter.status = !this.fields.counter.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/counter`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.counter.status = !this.fields.counter.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/counter`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleGetInTouch() {
      let value = this.fields.get_in_touch.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.get_in_touch.status = !this.fields.get_in_touch.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/getintouch`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.get_in_touch.status = !this.fields.get_in_touch.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/getintouch`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleFaqs() {
      let value = this.fields.faqs.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.faqs.status = !this.fields.faqs.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/faqs`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.faqs.status = !this.fields.faqs.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/faqs`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleWidget() {
      let value = this.fields.widget.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.widget.status = !this.fields.widget.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/widget`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.widget.status = !this.fields.widget.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/widget`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleFooter() {
      let value = this.fields.footer.status;
      if (value === false) {
        if (!confirm(this.toggleDisMessage)) {
          setTimeout(() => {
            this.fields.footer.status = !this.fields.footer.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/footer`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!confirm(this.toggleEnMessage)) {
          setTimeout(() => {
            this.fields.footer.status = !this.fields.footer.status;
          }, 200);
          return;
        }
        this.loading = true;
        this.$http
          .get(`/api/v1/mlo/landing/config/${this.id}/toggle/footer`)
          .catch((err) => {
            this.$store.dispatch(
              "OPEN_STATUS_NOTIFICATION",
              (this.statusNotification = {
                msg: err.response.statusText,
                status: "error",
                delay: 2000,
              })
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    /** Validation Section */
    validateHeader() {
      let target = this.fields.header;
      let response = true;
      if (target.login_url.trim() === "") {
        response = false;
      }
      if (target.title.trim() === "") {
        response = false;
      }
      if (target.description.trim() === "") {
        response = false;
      }
      if (target.btn_one.text.trim() === "") {
        response = false;
      }
      if (target.btn_one.url.trim() === "") {
        response = false;
      }
      if (target.btn_two.text.trim() === "") {
        response = false;
      }
      if (target.btn_two.url.trim() === "") {
        response = false;
      }
      return response;
    },
    validateWhyChoose() {
      let target = this.fields.why_choose;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      for (let content of target.content) {
        if (content.text.trim() === "") {
          response = false;
        }
      }
      return response;
    },
    validateCurrentRate() {
      let target = this.fields.current_rate;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      if (target.button.text.trim() === "") {
        response = false;
      }
      if (target.button.url.trim() === "") {
        response = false;
      }
      if (target.lowRate.trim() === "") {
        response = false;
      }
      if (target.highRate.trim() === "") {
        response = false;
      }
      return response;
    },
    validateLoanOptions() {
      let target = this.fields.loan_option;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      for (let content of target.content) {
        if (content.title.trim() === "") {
          response = false;
        }
        if (content.description.trim() === "") {
          response = false;
        }
        if (content.button.text.trim() === "") {
          response = false;
        }
        if (content.button.url.trim() === "") {
          response = false;
        }
      }
      return response;
    },
    validateHowItWorks() {
      let target = this.fields.how_it_work;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      if (target.btn_one.text.trim() === "") {
        response = false;
      }
      if (target.btn_one.url.trim() === "") {
        response = false;
      }
      if (target.btn_two.text.trim() === "") {
        response = false;
      }
      if (target.btn_two.url.trim() === "") {
        response = false;
      }
      for (let content of target.content) {
        if (content.title.trim() === "") {
          response = false;
        }
        if (content.description.trim() === "") {
          response = false;
        }
      }
      return response;
    },
    validateReviews() {
      let target = this.fields.review;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      return response;
    },
    validateRatings() {
      let target = this.fields.rating;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      return response;
    },
    validateLenders() {
      let target = this.fields.lender;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      return response;
    },
    validateTeams() {
      let target = this.fields.team;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      if (target.button.text.trim() === "") {
        response = false;
      }
      if (target.button.url.trim() === "") {
        response = false;
      }
      return response;
    },
    validateCounters() {
      let target = this.fields.counter;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      return response;
    },
    validateGetInTouch() {
      let target = this.fields.get_in_touch;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      if (target.description.trim() === "") {
        response = false;
      }
      if (target.btn_one.text.trim() === "") {
        response = false;
      }
      if (target.btn_one.url.trim() === "") {
        response = false;
      }
      if (target.btn_two.text.trim() === "") {
        response = false;
      }
      if (target.btn_two.url.trim() === "") {
        response = false;
      }
      return response;
    },
    validateFaqs() {
      let target = this.fields.faqs;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      if (target.other.trim() === "") {
        response = false;
      }
      for (let content of target.content) {
        if (content.title.trim() === "") {
          response = false;
        }
        if (content.description.trim() === "") {
          response = false;
        }
      }
      return response;
    },
    validateWidget() {
      let target = this.fields.widget;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      if (target.description.trim() === "") {
        response = false;
      }
      if (target.btn_one.text.trim() === "") {
        response = false;
      }
      if (target.btn_one.url.trim() === "") {
        response = false;
      }
      if (target.btn_two.text.trim() === "") {
        response = false;
      }
      if (target.btn_two.url.trim() === "") {
        response = false;
      }
      return response;
    },
    validateFooter() {
      let target = this.fields.footer;
      let response = true;
      if (target.title.trim() === "") {
        response = false;
      }
      if (target.contact.email.trim() === "") {
        response = false;
      }
      if (target.contact.phone.trim() === "") {
        response = false;
      }
      if (target.contact.address.trim() === "") {
        response = false;
      }
      for (let content of target.cms) {
        if (content.title.trim() === "") {
          response = false;
        }
        if (content.url.trim() === "") {
          response = false;
        }
      }
      for (let content of target.actions) {
        if (content.title.trim() === "") {
          response = false;
        }
        if (content.url.trim() === "") {
          response = false;
        }
      }
      for (let content of target.contact.social) {
        if (content.title.trim() === "") {
          response = false;
        }
        if (content.url.trim() === "") {
          response = false;
        }
      }
      return response;
    },

    // Core Values Section
    validateCore() {
      let title = this.coreFields.blockTitle;
      let header = this.coreFields.header;
      let blockOne = this.coreFields.blockOne;
      let blockTwo = this.coreFields.blockTwo;
      let blockThree = this.coreFields.blockThree;
      let blockFour = this.coreFields.blockFour;
      let blockFive = this.coreFields.blockFive;

      let response = true;
      if (title.trim() === "") {
        response = false;
      }
      if (header.title.trim() === "") {
        response = false;
      }
      if (header.description.trim() === "") {
        response = false;
      }
      if (blockOne.title.trim() === "") {
        response = false;
      }
      if (blockOne.description.trim() === "") {
        response = false;
      }
      if (blockTwo.title.trim() === "") {
        response = false;
      }
      if (blockTwo.description.trim() === "") {
        response = false;
      }
      if (blockThree.title.trim() === "") {
        response = false;
      }
      if (blockThree.description.trim() === "") {
        response = false;
      }
      if (blockFour.title.trim() === "") {
        response = false;
      }
      if (blockFour.description.trim() === "") {
        response = false;
      }
      if (blockFive.title.trim() === "") {
        response = false;
      }
      if (blockFive.description.trim() === "") {
        response = false;
      }

      return response;
    },
    validateReview() {
      let zillow = this.ratingFields.zillow;
      let google = this.ratingFields.google;
      let yelp = this.ratingFields.yelp;

      let response = true;
      if (zillow.review === "") {
        response = false;
      }
      if (zillow.rating === "") {
        response = false;
      }
      if (google.review === "") {
        response = false;
      }
      if (google.rating === "") {
        response = false;
      }
      if (yelp.review === "") {
        response = false;
      }
      if (yelp.rating === "") {
        response = false;
      }
      return response;
    },
    validWelcome() {
      let content = this.welcomeFunnel;

      let response = true;
      if (content.title === "") {
        response = false;
      }
      if (content.description === "") {
        response = false;
      }
      return response;
    },
    updateHeader(value) {
      this.fields.header = value;
    },
    updateWhyChoose(value) {
      this.fields.why_choose = value;
    },
    updateCurrentRate(value) {
      this.fields.current_rate = value;
    },
    updateLoanOptions(value) {
      this.fields.loan_option = value;
    },
    updateHowItWorks(value) {
      this.fields.how_it_work = value;
    },
    updateReviews(value) {
      this.fields.reviews = value;
    },
    updateRatings(value) {
      this.fields.ratings = value;
    },
    updateLenders(value) {
      this.fields.lenders = value;
    },
    updateTeam(value) {
      this.fields.team = value;
    },
    updateCounter(value) {
      this.fields.counter = value;
    },
    updateGetinTouch(value) {
      this.fields.get_in_touch = value;
    },
    updateFAQS(value) {
      this.fields.faqs = value;
    },
    updateWidget(value) {
      this.fields.widget = value;
    },
    updateFooter(value) {
      this.fields.footer = value;
    },

    // Core Values Section
    updateCoreHeader(value) {
      this.coreFields.header = value;
    },
    updateBlockTitle(value) {
      this.coreFields.blockTitle = value;
    },
    updateBlockOne(value) {
      this.coreFields.blockOne = value;
    },
    updateBlockTwo(value) {
      this.coreFields.blockTwo = value;
    },
    updateBlockThree(value) {
      this.coreFields.blockThree = value;
    },
    updateBlockFour(value) {
      this.coreFields.blockFour = value;
    },
    updateBlockFive(value) {
      this.coreFields.blockFive = value;
    },

    // Reviews section
    updateZillow(value) {
      this.ratingFields.zillow = value;
    },
    updateGoogle(value) {
      this.ratingFields.google = value;
    },
    updateYelp(value) {
      this.ratingFields.yelp = value;
    },
  },
  created() {
    this.loadForm();
  },
  computed: {
    /** Validate Form */
    validForm() {
      if (this.activeTab === "landing") {
        if (
          !this.validateHeader() ||
          !this.validateWhyChoose() ||
          !this.validateCurrentRate() ||
          !this.validateLoanOptions() ||
          !this.validateHowItWorks() ||
          !this.validateReviews() ||
          !this.validateRatings() ||
          !this.validateLenders() ||
          !this.validateTeams() ||
          !this.validateCounters() ||
          !this.validateGetInTouch() ||
          !this.validateFaqs() ||
          !this.validateWidget() ||
          !this.validateFooter()
        ) {
          return true;
        }
      } else if (this.activeTab === "core" && !this.validateCore()) {
        return true;
      } else if (this.activeTab === "rating" && !this.validateReview()) {
        return true;
      } else if (this.activeTab === "welcome_funnel" && !this.validWelcome()) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-save {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  font-size: 1.2rem;
  height: 3rem;
  width: 7rem;
  max-width: 100%;
  max-height: 100%;
  &:hover {
    box-shadow: 0px 0px 10px 0px lightgreen;
  }
}
</style>

<template>
  <fieldset class="mt-3">
    <div class="row">
      <div class="col-12">
        <div class="form-check form-switch">
          <input
            id="header-toggle"
            v-model="header.status"
            class="form-check-input"
            type="checkbox"
            @change="$emit('toggleHeader')"
          />
          <label class="form-check-label" for="header-toggle"
            >Hero Section</label
          >
        </div>
      </div>
      <div class="col-6 mt-3">
        <div class="form-group full-width">
          <input
            v-model="header.login_url"
            class="form-control"
            placeholder="User Login URL ..."
            type="url"
          />
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <div class="row">
          <div class="col-6">
            <fieldset>
              <span class="card-body-title">Title</span>
              <div class="card">
                <div class="card-body">
                  <div class="form-group full-width">
                    <label>Text</label>
                    <textarea
                      v-model="header.title"
                      class="form-control mt-2"
                      required
                      rows="6"
                    ></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset>
              <span class="card-body-title">Description</span>
              <div class="card">
                <div class="card-body">
                  <div class="form-group full-width">
                    <label>Text</label>
                    <textarea
                      v-model="header.description"
                      class="form-control mt-2"
                      required
                      rows="6"
                    ></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-6">
            <fieldset>
              <span class="card-body-title">Button One</span>
              <div class="card">
                <div class="card-body">
                  <div class="form-group full-width">
                    <label>Text</label>
                    <input
                      v-model="header.btn_one.text"
                      class="form-control mt-2"
                      required
                      type="text"
                    />
                  </div>
                  <div class="form-group full-width mt-3">
                    <label>URL</label>
                    <input
                      v-model="header.btn_one.url"
                      class="form-control mt-2"
                      required
                      type="url"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset>
              <span class="card-body-title">Button Two</span>
              <div class="card">
                <div class="card-body">
                  <div class="form-group full-width">
                    <label>Text</label>
                    <input
                      v-model="header.btn_two.text"
                      class="form-control mt-2"
                      required
                      type="text"
                    />
                  </div>
                  <div class="form-group full-width mt-3">
                    <label>URL</label>
                    <input
                      v-model="header.btn_two.url"
                      class="form-control mt-2"
                      required
                      type="url"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "HeroSection",
  props: {
    headerData: { type: Object }
  },
  data() {
    return {
      header: this.headerData
    };
  },
  watch: {
    header: {
      handler(val) {
        this.$emit("update-header", val);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.card-footer-title {
  min-height: 285px;
}
.card-fixed {
  height: auto;
  max-height: 650px;
  overflow-y: auto;
}
.card-body {
  min-height: 250px;
}
.card-body-title {
  color: #6c757d;
  display: inline-block;
  margin-bottom: 10px;
}
.full-width {
  max-width: 100%;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
}
.form-group {
  textarea {
    line-height: 20px;
  }
}
.form-switch {
  padding-left: 0;
  .form-check-input {
    margin: 0;
  }
  .form-check-label {
    cursor: pointer;
  }
}
</style>

<template>
  <fieldset class="mt-3">
    <div class="row mt-3">
      <div class="col-12">
        <div class="form-check form-switch">
          <input
            id="why-choose-us-toggle"
            v-model="whyChoose.status"
            class="form-check-input"
            type="checkbox"
            @change="$emit('toggleWhyChoose')"
          />
          <label class="form-check-label" for="why-choose-us-toggle"
            >Why Choose Us</label
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <span class="d-block">Content</span>
        <button
          v-show="showHideAddMoreWhyChoose"
          class="btn btn-outline-dark mt-2"
          @click="addMoreWhyChoose"
        >
          Add More
        </button>
      </div>
      <div class="row">
        <div class="col-6">
          <fieldset>
            <div
              v-for="(content, index) in whyChoose.content"
              :key="index"
              class="row mt-3"
            >
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <img
                      alt="Close"
                      class="cursor-pointer mb-2 float-right"
                      src="@/assets/icons/icon-close.svg"
                      @click="removeWhyChoose(index)"
                    />
                    <div class="form-group full-width">
                      <label>Text</label>
                      <textarea
                        v-model="content.text"
                        class="form-control mt-2"
                        required
                        rows="6"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-6">
          <fieldset>
            <div class="card mt-3">
              <div class="card-body">
                <div class="form-group full-width">
                  <label>Title</label>
                  <textarea
                    v-model="whyChoose.title"
                    class="form-control mt-2"
                    required
                    rows="6"
                  ></textarea>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "WhyChooseUs",
  props: {
    whyChooseData: { type: Object }
  },
  data() {
    return {
      whyChoose: this.whyChooseData
    };
  },
  methods: {
    addMoreWhyChoose() {
      this.whyChoose.content.push({ text: "" });
    },
    removeWhyChoose(index) {
      this.$delete(this.whyChoose.content, index);
    }
  },
  watch: {
    whyChoose: {
      handler(val) {
        this.$emit("update-whychoose", val);
      },
      deep: true
    }
  },
  computed: {
    /** Show Hide Add More Button Section */
    showHideAddMoreWhyChoose() {
      let target = this.whyChoose.content;
      let len = target.length;
      if (len > 0) {
        if (len === 6) return false;
        let content = target[len - 1];
        if (content.text.trim() === "") {
          return false;
        }
        return true;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-footer-title {
  min-height: 285px;
}
.card-fixed {
  height: auto;
  max-height: 650px;
  overflow-y: auto;
}
.card-body {
  min-height: 250px;
}
.card-body-title {
  color: #6c757d;
  display: inline-block;
  margin-bottom: 10px;
}
.full-width {
  max-width: 100%;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
}
.form-group {
  textarea {
    line-height: 20px;
  }
}
.form-switch {
  padding-left: 0;
  .form-check-input {
    margin: 0;
  }
  .form-check-label {
    cursor: pointer;
  }
}
</style>

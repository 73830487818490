<template>
  <fieldset class="mt-3">
    <div class="row mt-3">
      <div class="col-12">
        <div class="form-check form-switch">
          <input
            id="lender-toggle"
            v-model="lenders.status"
            class="form-check-input"
            type="checkbox"
            @change="$emit('toggleLender')"
          />
          <label class="form-check-label" for="lender-toggle"
            >Our Lenders</label
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <fieldset>
          <caption class="full-width">
            Title
          </caption>
          <div class="card">
            <div class="card-body">
              <div class="form-group full-width">
                <label>Text</label>
                <textarea
                  class="form-control mt-2"
                  rows="6"
                  required
                  v-model="lenders.title"
                ></textarea>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "Lenders",
  props: {
    lendersData: { type: Object }
  },
  data() {
    return {
      lenders: this.lendersData
    };
  },
  watch: {
    lenders: {
      handler(val) {
        this.$emit("update-lenders", val);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.card-footer-title {
  min-height: 285px;
}
.card-fixed {
  height: auto;
  max-height: 650px;
  overflow-y: auto;
}
.card-body {
  min-height: 250px;
}
.card-body-title {
  color: #6c757d;
  display: inline-block;
  margin-bottom: 10px;
}
.full-width {
  max-width: 100%;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
}
.form-group {
  textarea {
    line-height: 20px;
  }
}
.form-switch {
  padding-left: 0;
  .form-check-input {
    margin: 0;
  }
  .form-check-label {
    cursor: pointer;
  }
}
</style>

<template>
  <fieldset class="mt-3">
    <div class="row mt-3">
      <div class="col-12">
        <div class="form-check form-switch">
          <input
            id="footer-toggle"
            v-model="footer.status"
            class="form-check-input"
            type="checkbox"
            @change="$emit('toggleFooter')"
          />
          <label class="form-check-label" for="footer-toggle"
            >Footer Section</label
          >
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <fieldset>
          <span class="card-body-title">Title</span>
          <div class="card card-footer-title">
            <div class="card-body">
              <div class="form-group full-width">
                <label>Text</label>
                <textarea
                  v-model="footer.title"
                  class="form-control mt-2"
                  required
                  rows="6"
                ></textarea>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-6">
        <fieldset>
          <span class="card-body-title">Contact</span>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="form-group full-width">
                        <label>Address</label>
                        <textarea
                          v-model="footer.contact.address"
                          class="form-control mt-2"
                          required
                          rows="6"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="form-group full-width">
                        <label>Email</label>
                        <input
                          type="email"
                          class="form-control mt-2"
                          required
                          v-model="footer.contact.email"
                        />
                      </div>
                      <div class="form-group full-width mt-3">
                        <label>Phone</label>
                        <input
                          type="text"
                          class="form-control mt-2"
                          required
                          v-model="footer.contact.phone"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="card card-fixed">
          <div class="card-body">
            <span class="d-block">CMC</span>
            <button
              v-show="showHideAddMoreCms"
              class="btn btn-outline-dark mt-2"
              @click="addMoreCms"
            >
              Add More
            </button>
            <div
              v-for="(content, index) in footer.cms"
              :key="index"
              class="row my-3"
            >
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <img
                      alt="Close"
                      class="cursor-pointer mb-2 float-right"
                      src="@/assets/icons/icon-close.svg"
                      @click="removeCms(index)"
                    />
                    <div class="form-group full-width">
                      <label>Text</label>
                      <input
                        type="text"
                        v-model="content.title"
                        class="form-control mt-2"
                        required
                      />
                    </div>
                    <div class="form-group full-width">
                      <label>Url</label>
                      <input
                        type="url"
                        v-model="content.url"
                        class="form-control mt-2"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card card-fixed">
          <div class="card-body">
            <span class="d-block">Actions</span>
            <button
              v-show="showHideAddMoreAction"
              class="btn btn-outline-dark mt-2"
              @click="addMoreAction"
            >
              Add More
            </button>
            <div
              v-for="(content, index) in footer.actions"
              :key="index"
              class="row my-3"
            >
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <img
                      alt="Close"
                      class="cursor-pointer mb-2 float-right"
                      src="@/assets/icons/icon-close.svg"
                      @click="removeAction(index)"
                    />
                    <div class="form-group full-width">
                      <label>Text</label>
                      <input
                        type="text"
                        v-model="content.title"
                        class="form-control mt-2"
                        required
                      />
                    </div>
                    <div class="form-group full-width">
                      <label>Url</label>
                      <input
                        type="url"
                        v-model="content.url"
                        class="form-control mt-2"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card card-fixed">
          <div class="card-body">
            <span class="d-block">Social Accounts</span>
            <div
              v-for="(content, index) in footer.contact.social"
              :key="index"
              class="row my-3"
            >
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="form-check form-switch">
                      <input
                        :id="'social-toggle' + index"
                        v-model="content.status"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label
                        class="form-check-label"
                        :for="'social-toggle' + index"
                        >Status</label
                      >
                    </div>
                    <div class="form-group full-width mt-3">
                      <label>Title</label>
                      <input
                        type="text"
                        v-model="content.title"
                        class="form-control mt-2"
                        readonly
                        required
                      />
                    </div>
                    <div class="form-group full-width mt-3">
                      <label>Url</label>
                      <input
                        type="url"
                        v-model="content.url"
                        class="form-control mt-2"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "Footer",
  props: {
    footerData: { type: Object }
  },
  data() {
    return {
      footer: this.footerData
    };
  },
  methods: {
    addMoreCms() {
      this.footer.cms.push({
        title: "",
        url: ""
      });
    },
    removeCms(index) {
      this.$delete(this.footer.cms, index);
    },
    addMoreAction() {
      this.footer.actions.push({
        title: "",
        url: ""
      });
    },
    removeAction(index) {
      this.$delete(this.footer.actions, index);
    }
  },
  watch: {
    footer: {
      handler(val) {
        this.$emit("update-footer", val);
      },
      deep: true
    }
  },
  computed: {
    /** Show Hide Add More Button Section */
    showHideAddMoreCms() {
      let target = this.footer.cms;
      let len = target.length;
      if (len > 0) {
        let content = target[len - 1];
        if (content.title.trim() === "") {
          return false;
        }
        if (content.url.trim() === "") {
          return false;
        }
        return true;
      }
      return true;
    },
    showHideAddMoreAction() {
      let target = this.footer.actions;
      let len = target.length;
      if (len > 0) {
        let content = target[len - 1];
        if (content.title.trim() === "") {
          return false;
        }
        if (content.url.trim() === "") {
          return false;
        }
        return true;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-footer-title {
  min-height: 285px;
}
.card-fixed {
  height: auto;
  max-height: 650px;
  overflow-y: auto;
}
.card-body {
  min-height: 250px;
}
.card-body-title {
  color: #6c757d;
  display: inline-block;
  margin-bottom: 10px;
}
.full-width {
  max-width: 100%;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
}
.form-group {
  textarea {
    line-height: 20px;
  }
}
.form-switch {
  padding-left: 0;
  .form-check-input {
    margin: 0;
  }
  .form-check-label {
    cursor: pointer;
  }
}
</style>

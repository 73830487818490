<template>
  <fieldset class="mt-3">
    <div class="row mt-3">
      <div class="col-12">
        <div class="form-check form-switch">
          <input
            id="loan-options-toggle"
            v-model="loanOptions.status"
            class="form-check-input"
            type="checkbox"
            @change="$emit('toggleLoanOption')"
          />
          <label class="form-check-label" for="loan-options-toggle"
            >Loan Options</label
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <span class="d-block">Content</span>
        <button
          v-show="showHideAddMoreLoanOption"
          class="btn btn-outline-dark mt-2"
          @click="addMoreLoanOption"
        >
          Add More
        </button>
      </div>
      <div class="row">
        <div class="col-8">
          <fieldset>
            <div
              v-for="(content, index) in loanOptions.content"
              :key="index"
              class="card mt-3"
            >
              <div class="card-body">
                <img
                  alt="Close"
                  class="cursor-pointer mb-2 float-right"
                  src="@/assets/icons/icon-close.svg"
                  @click="removeLoanOption(index)"
                />
                <div class="row">
                  <div class="col-4">
                    <fieldset>
                      <span>Title</span>
                      <div class="card mt-3">
                        <div class="card-body">
                          <div class="form-group full-width">
                            <label>Text</label>
                            <textarea
                              v-model="content.title"
                              class="form-control mt-2"
                              required
                              rows="6"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="col">
                    <fieldset>
                      <span>Description</span>
                      <div class="card mt-3">
                        <div class="card-body">
                          <div class="form-group full-width">
                            <label>Text</label>
                            <textarea
                              v-model="content.description"
                              class="form-control mt-2"
                              required
                              rows="6"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="col">
                    <fieldset>
                      <span>Button</span>
                      <div class="card mt-3">
                        <div class="card-body">
                          <div class="form-group full-width">
                            <label>Text</label>
                            <input
                              v-model="content.button.text"
                              class="form-control mt-2"
                              required
                              type="text"
                            />
                          </div>
                          <div class="form-group full-width mt-3">
                            <label>URL</label>
                            <input
                              v-model="content.button.url"
                              class="form-control mt-2"
                              required
                              type="url"
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-4">
          <fieldset>
            <div class="card mt-3">
              <div class="card-body">
                <div class="form-group full-width">
                  <label>Title</label>
                  <textarea
                    v-model="loanOptions.title"
                    class="form-control mt-2"
                    required
                    rows="6"
                  ></textarea>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "LoanOptions",
  props: {
    loanOptionsData: { type: Object }
  },
  data() {
    return {
      loanOptions: this.loanOptionsData
    };
  },
  methods: {
    addMoreLoanOption() {
      this.loanOptions.content.push({
        title: "",
        description: "",
        button: {
          text: "",
          url: ""
        }
      });
    },
    removeLoanOption(index) {
      this.$delete(this.loanOptions.content, index);
    }
  },
  watch: {
    loanOptions: {
      handler(val) {
        this.$emit("update-loanoptions", val);
      },
      deep: true
    }
  },
  computed: {
    /** Show Hide Add More Button Section */
    showHideAddMoreLoanOption() {
      let target = this.loanOptions.content;
      let len = target.length;
      if (len > 0) {
        if (len === 8) return false;
        let content = target[len - 1];
        if (content.title.trim() === "") {
          return false;
        }
        if (content.description.trim() === "") {
          return false;
        }
        if (content.button.text.trim() === "") {
          return false;
        }
        if (content.button.url.trim() === "") {
          return false;
        }
        return true;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-footer-title {
  min-height: 285px;
}
.card-fixed {
  height: auto;
  max-height: 650px;
  overflow-y: auto;
}
.card-body {
  min-height: 250px;
}
.card-body-title {
  color: #6c757d;
  display: inline-block;
  margin-bottom: 10px;
}
.full-width {
  max-width: 100%;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
}
.form-group {
  textarea {
    line-height: 20px;
  }
}
.form-switch {
  padding-left: 0;
  .form-check-input {
    margin: 0;
  }
  .form-check-label {
    cursor: pointer;
  }
}
</style>
